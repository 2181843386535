import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <section className="flex flex-col justify-center items-center bg-gray-100 h-screen">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl font-bold mb-4">Welcome to My Blog</h1>
          <p className="text-lg text-gray-600 mb-8">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
      </section>
    </>
  );
};

export default Home;
